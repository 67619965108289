import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(2)
  }
}));

export default function CustomAutocomplete(props) {
  const classes = useStyles();

  const data = {
    options: props.data,
    getOptionLabel: option => option.title,
  };

  const change = (event, value) => {
    return props.handlers.change(props.name, value);
  };

  return (
    <div>
      <FormControl fullWidth className={classes.formControl} error={props.error}>
        <Autocomplete
          {...data}
          id="debug"
          debug
          defaultValue={props.value}
          renderInput={params => <TextField {...params} label={props.label} fullWidth name={props.name}/>}
          onChange={change}
          data-test-id={props["data-test-id"]}
        />
        <FormHelperText>{props.message}</FormHelperText>
      </FormControl>
    </div>
  );
}
