import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Form from './components/Form';

class MailingList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  get breadcrumbs() {
    return [
      {
        title: "CRM"
      },
      {
        title: "MailingList"
      }
    ];
  }

  render() {
    return (
      <div style={{marginTop: "100px", flexGrow: 1}}>
        <Grid
          container
          spacing={3}
          direction="column"
          justify="center"
          alignItems="center"
          data-test-id="mailingListBody"
        >
          <Grid item xs={12}>
            <Typography variant="h3">
              Mailing list
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Form user={this.props.user}/>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default MailingList;
