import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MailIcon from '@material-ui/icons/Mail';
import DescriptionIcon from '@material-ui/icons/Description';
import { MenuList, MenuItem } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import MailingList from './mailingList/index';
import Templates from './templates/index';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  nameUser: {
    marginLeft: "auto",
    marginRight: -12
  }
}));

export default function PersistentDrawerLeft(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  return (
    <div className={classes.root}>
      <Router>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {})}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer('left', true)}
              edge="start"
              className={clsx(classes.menuButton, classes.hide)}
              data-test-id="mainMenu_button"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              CRM
            </Typography>
            <Typography variant="h6" noWrap className={classes.nameUser}>
              {props.user.name}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
        <MenuList className={classes.list} data-test-id="mainMenu">
          <MenuItem component={Link} to="/maillists" data-test-id="mailingList_button">
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            Mailing list
          </MenuItem>
          <MenuItem component={Link} to="/templates">
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            Templates
          </MenuItem>
        </MenuList>
        </Drawer>
        <Switch>
          <Route path="/maillists" component={() => <MailingList user={props.user}/>} />
          <Route path="/templates" component={() => <Templates user={props.user}/>} />
        </Switch>
      </Router>
    </div>
  );
}
