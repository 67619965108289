import env from "environments";

export default class FetchRequest {
  constructor() {}

  loggin() {
    return fetch(env.endpoints.get.login, {
      method: "get",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include"
    })
    .then(response => response.json())
    .then(body => {
      if(body.status === 500) {
        window.location = env.redirect.url;
      }

      return body;
    });
  }

  /**
   *
   * @todo Healthy API shouldn't use stringifying body in POST-requests
   * @param url
   * @param apiKey
   * @param body
   * @returns {Promise<any>}
   */
  postRequest(url, apiKey, body) {
    this.loggin();
    return fetch(url, {
      method: "post",
      headers: {
        "X-Api-Key": apiKey,
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
    .then(response => response.json())
  }
}