import React, { Component } from 'react';
import Index from "./pages/index";
import FetchRequest from './services/request';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      user: {}
    };

    this._fetchRequest = new FetchRequest();
  }
  componentDidMount() {
      this._fetchRequest
      .loggin()
      .then(body => {
        if(body.user !== null) {
          this.setState({
            user: {
              apiKey: body.user.apiKey,
              name: body.user.name
            },
            loading: false
          });
          
        }
      });
  }
  render() {
    return (
      <div>
        {this.state.loading ? "Loading..." : <Index user={this.state.user}/>}
      </div>      
    );
  }
}

export default App;
