const env = `${process.env.REACT_APP_ENV || "development"}`;

const domains = {
  development: "http://localhost:3001",
  rel: "https://rel-admin-dmp.insigit.com",
  production: "https://admin-dmp.insigit.com"
};

const url = domains[env];

export default (() => ({
  endpoints: {
    get: {
      getLists: `${url}/api/v1/crm/lists`,
      login: `${url}/crm/login`
    },
    post: {
      saveMailingList: `${url}/api/v1/crm/add`,
      getMailingLists: `${url}/api/v1/crm/maillists`,
      readyToSend: `${url}/api/v1/crm/maillists/send`,
      cancelSend: `${url}/api/v1/crm/maillists/send`,
      testSend: `${url}/api/v1/crm/maillists/testSend`,
      immediateSend: `${url}/api/v1/crm/maillists/sendNow`,
      viewMailingList: `${url}/api/v1/crm/view`
    }
  },
  redirect: {
    url
  }
}))();