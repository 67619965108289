import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import moment from "moment";

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(2)
  }
}));

export default function CustomDatePicker(props) {
  const classes = useStyles();

  const disabledPastTime = (date) => {
    return moment(date).format() < moment().subtract(1, "d").format();
  };

  const change = (event, value) => {
    value = `${value} ${moment().format("HH:mm:ss")}`;
    return props.handlers.change(props.name, value);
  };

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="normal"
          label={props.date}
          format={props.format}
          className={classes.formControl}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          value={props.value}
          onChange={change}
          shouldDisableDate={disabledPastTime}
          inputProps={{"data-test-id": props["data-test-id"]}}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}
