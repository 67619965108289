import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export default function CustomSwitch(props) {
  // const [checked, setChecked] = React.useState(false);

  const change = (event) => {
    // setChecked(event.target.checked);
    return props.handlers.changeTargetingType(event.target.checked);
  };

  return (
    <div>
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item xs={2}>Country targeting</Grid>
        <Grid item xs={1}>
          <FormControlLabel
            control={
              <Switch
                checked={props.checked}
                onChange={change}
                value="checked"
                color="primary"
                disabled={props.disabled}
                data-test-id={props["data-test-id"]}
              />
            }
          />
        </Grid>
        <Grid item xs={1}>Emails</Grid>
      </Grid>
    </div>
  );
}
