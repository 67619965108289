import React  from 'react';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function View(props) {
  const handleClose = () => {
    props.handlers.close();
  };

  const tableRow = (row) => {
    if((row.handlers === undefined || row.handlers["hidden"] === undefined) || (row.handlers && row.handlers["hidden"] && row.handlers["hidden"](props.row))) {
      return <TableRow key={row.name}>
        <TableCell align="left"><b>{row.label}</b></TableCell>
        <TableCell align="left">{row.handlers && row.handlers[row.name] ? row.handlers[row.name](props.row[row.name]) : props.row[row.name]}</TableCell>
      </TableRow>
    }
  };

  return (
    <div>
      <Dialog open={props.isOpen} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md" onClose={handleClose}>
          <DialogTitle id="form-dialog-title">
            Mail list #{props.row.id}
          </DialogTitle>
          <DialogContent>
            <Table aria-label="simple table">
              <TableBody>
                {
                  props.fields.map(row => (
                    tableRow(row)
                  ))
                }
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}