import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class Templates extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{marginTop: "100px", flexGrow: 1}}>
        <Grid 
          container 
          spacing={3} 
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography variant="h3">
              Templates
            </Typography>
          </Grid>
          <Grid item xs={12}>  
            <iframe src="https://crm-tds.insigit.com/templates" title="CRM Templates" align="left" style={{
              width: "1400px",
              height: "800px",
              display: "block",
              margin: "20px 0px",
              padding: "0px",
              border: "none"
            }}/>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Templates;