import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(2)
  }
}));

export default function CustomInput(props) {
  const classes = useStyles();

  const change = (event) => {
    return props.handlers.change(event.target.name, event.target.value);
  };

  return (
    <div>
      <TextField
        className={classes.formControl}
        label={props.label}
        name={props.name}
        value={props.value || ""}
        type="text"
        error={props.error}
        helperText={props.message}
        fullWidth
        onChange={change}
        inputProps={{"data-test-id": props["data-test-id"]}}
      />
    </div>
  );
}
