import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(2)
  }
}));

export default function CustomSelect(props) {
  const classes = useStyles();

  const change = (event) => {
    return props.handlers.change(event.target.name, event.target.value);
  };

  const isSelected = (item) => {
    return +(item.id || item) === +props.value;
  };

  return (
    <div>
      <FormControl fullWidth className={classes.formControl} error={props.error} data-test-id={props["data-test-id"]}>
        <InputLabel>{props.label}</InputLabel>
        <Select
          value={props.value || ""}
          name={props.name}
          onChange={change}
        >
          <MenuItem value="" key="" selected={["", null, undefined].includes(props.value)} />
          {
            props.data.map(item => {
              return (
                <MenuItem value={item.id||item} key={item.id||item} selected={isSelected(item)}>{item.title||item}</MenuItem>
              )
            })
          }
        </Select>
        <FormHelperText>{props.message}</FormHelperText>
      </FormControl>
    </div>
  );
}
